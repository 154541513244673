<template>
  <div class="otpForm">
    <div class="loginContainer">
      <div class="login-title">
        <div>
          {{ __("Verify OTP") }}
        </div>
      </div>
      <el-form
        @submit.prevent.native
        ref="otpForm"
        :rules="rules"
        label-position="top"
        label-width="100px"
        :model="credentials"
        hide-required-asterisk
      >
        <el-form-item
          prop="one_time_password"
          :label="__('One Time Password')"
          class="password-form-item text"
        >
          <span
            slot="label"
            @click="redirectToReset2FA"
            class="que text reset-2fa-text"
          >
            {{ __("Reset 2FA") }}
          </span>
          <InputTFA
            v-model="credentials.one_time_password"
            name="one_time_password"
            @keydown.enter.native="submitForm"
          ></InputTFA>
        </el-form-item>

        <el-form-item>
          <el-button
            :disabled="!credentials.one_time_password.length > 0"
            id="sign-in-btn"
            class="w-full text"
            type="primary"
            :loading="isSubmitting"
            @click="submitForm"
          >
            {{ __("VERIFY") }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import store from "@/store";
import { getQueryParams } from "@/utils/transformers";
import _ from "lodash";
import Translation from "@/mixins/translation";
import InputTFA from "@/components/2FA/InputTFA";

export default {
  components: { InputTFA },
  name: "VerifyOtp",
  mixins: [Translation],
  data() {
    const checkOtpFormat = (rule, value, callback) => {
      if (/^\d*$/.test(value) === false) {
        callback(__("one time password cannot be text"));
      } else if (!this.credentials.one_time_password.length) {
        callback(__("one time password cannot be empty"));
      } else {
        callback();
      }
    };
    return {
      credentials: {
        one_time_password: "",
        email: "",
        password: ""
      },
      redirectUrl: "",
      otpLinkToken: "",
      rules: {
        one_time_password: [
          {
            required: true,
            trigger: "blur",
            validator: checkOtpFormat
          }
        ]
      },
      isSubmitting: false
    };
  },
  async mounted() {
    this.credentials.email = this.$route.params.email;
    this.credentials.password = this.$route.params.password;
    this.redirectUrl = this.$route.params.redirectUrl;
    this.otpLinkToken = this.$route.params.otpLinkToken;
  },
  methods: {
    ...mapActions("auth", {
      verifyOtp: "verifyOtp"
    }),
    redirectToReset2FA() {
      this.$router.push({
        name: "Forgot",
        params: { redirectFrom: "2FA" }
      });
    },
    submitForm() {
      let self = this;
      this.$refs.otpForm.validate(valid => {
        if (valid) {
          self.isSubmitting = true;
          let obj = {
            one_time_password: self.credentials.one_time_password,
            otp_link_token: self.otpLinkToken
          };
          this.verifyOtp(obj)
            .then(data => {
              if (data.data.otp_verified) {
                this.$auth.login({
                  data: {
                    email: self.credentials.email,
                    password: self.credentials.password
                  },
                  fetchUser: false,
                  success: function(res) {
                    if (res.data.token) {
                      self.$auth.fetch().then(async userReq => {
                        this.translateForUser(userReq.data);
                        let obj = {};
                        if (self.redirectUrl) {
                          let searchParams = getQueryParams(self.redirectUrl);
                          let ac_id = searchParams.get("ac_id");
                          let sp_id = searchParams.get("sp_id");
                          if (sp_id) {
                            obj.sp_id = sp_id;
                          }
                          if (ac_id) {
                            obj.ac_id = ac_id;
                          }
                        }
                        if (!_.isEmpty(obj)) {
                          await store.dispatch(
                            "app/generateUserAccountSwitcherOptions",
                            obj
                          );
                        } else {
                          await store.dispatch(
                            "app/generateUserAccountSwitcherOptions"
                          );
                        }
                        if (!self.redirectUrl) {
                          this.$router
                            .push("/")
                            .then(() => {
                              self.isSubmitting = false;
                            })
                            .catch(() => {});
                        } else {
                          this.$router
                            .push(self.redirectUrl)
                            .then(() => {
                              self.isSubmitting = false;
                            })
                            .catch(() => {});
                        }
                      });
                    }
                  },
                  error: function(err) {
                    self.isSubmitting = false;
                    self.$message({
                      message: err.message || __("Operation Failed"),
                      type: "error"
                    });
                  }
                });
              }
            })
            .catch(err => {
              self.isSubmitting = false;
              let msg = __("Invalid OTP");
              if (err !== undefined) {
                msg = err.message;
              }
              this.$message({
                message: msg,
                type: "error"
              });
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";
.otpForm {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginContainer {
  padding-top: 80px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  width: 400px;
  background: #ffffff;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  .text {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  .login-title {
    font-weight: bolder;
    font-size: 36px;
    display: flex;
    flex-direction: row;
    color: $--color-text-primary;
    margin-bottom: 50px;
    .welcome-dot {
      text-shadow: 0 4px 4px rgba(28, 209, 161, 0.26);
      color: $--color-primary;
    }
  }

  .loginOptions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  ::v-deep .el-form-item--medium .el-form-item__label {
    line-height: 7px;
  }

  ::v-deep .el-input--medium .el-input__inner {
    height: 50px;
    line-height: 50px;
  }

  ::v-deep .el-form-item__label {
    display: flex;
    justify-content: right;
  }

  .email-form-item {
    margin-bottom: 60px;
  }

  .password-form-item {
    margin-bottom: 36px;
  }

  #sign-in-btn {
    border-radius: 5px;
    height: 50px;
  }

  .que {
    cursor: pointer;
  }

  .google-sign-in {
    color: $--color-secondary;

    &:hover {
      color: mix($--color-black, $--color-secondary, 20%);
    }
  }

  .reset-2fa-text {
    color: var(--theme-color);

    &:hover {
      color: var(--theme-hover-color);
    }
  }
}

.w-full {
  width: 100%;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
